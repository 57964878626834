//Preloader
$(window).on('load', function() {
  $("#over").fadeOut().remove();
  $("#preload").delay(550).fadeOut("slow").remove();
});
//End Preloader

//Automatic update year
$(document).ready(function () {
  var d = new Date();
  var output = d.getFullYear();
  $('.yearval').text(output);
});
//End

// Анимированная стрелка вниз

// $(function() {
//   setTimeout('topArrow()');
// });

// function topArrow() {
//   $('.home-main__arrow img').animate({
//     marginTop: 25
//   }, 1000).animate({
//     marginTop: 35 
//   }, 1000);
//   setTimeout('topArrow()', 1600);
// };

// Плавный переход к якорю
$(document).ready(function () {
  $(".transfer-slider__item, .btn--alltariffs, .soft-scroll").on("click", function (a) {
    a.preventDefault();
    var c = $(this).attr("href"),
      t = $(c).offset().top;
    $("body,html").animate({
      scrollTop: t
    }, 1e3)
  })
});


$(document).ready(function(){
  if ((window.location.pathname == "/")||(window.location.pathname == "/index.html")) {
    $('.allbeauty').slick({
      slidesToShow: 5,
      touchThreshold:10,
      prevArrow:'<button type="button" class="slick-prev"><img src="img/arrow-right.svg" alt="<" /></button>',
      nextArrow:'<button type="button" class="slick-next"><img src="img/arrow-right.svg" alt=">" /></button>',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
      ]
    });
  };
});


$(document).ready(function(){
  if (window.location.pathname == "/transfer.html") {
    $('.transfer-destinations').slick({
      slidesToShow: 5,
      touchThreshold:10,
      autoplay: true,
      prevArrow:'<button type="button" class="slick-prev"><img src="img/arrow-right.svg" alt="<" /></button>',
      nextArrow:'<button type="button" class="slick-next"><img src="img/arrow-right.svg" alt=">" /></button>',

    });
  };
});




$(document).ready(function(){
  $('.spoiler-body').hide(300);
  $(".spoiler-wrap").click(function (e){
    e.preventDefault();
    if ($(this).hasClass('active')){
      $(this).removeClass('active');
      $(this).find('.spoiler-body').slideToggle();
      $(this).toggleClass("icon-plus-square");
      $(this).toggleClass("icon-minus-square");
    }
    else
    {
      $('.spoiler-wrap').removeClass('active');
      $('.spoiler-wrap').find('.spoiler-body').slideUp();
      $(this).addClass('active');
      $(this).find('.spoiler-body').slideToggle();
    }
  })
})




//- Menu close/open
$(document).ready(function () {
	$(".menu-button").click(function () {
		$(this).toggleClass("menu-button--to-open"),
		$(this).toggleClass("menu-button--to-close"),
		$(this).hasClass("menu-button--to-close") ? $(".menu").addClass("menu--visible") : $(".menu").removeClass("menu--visible")
	})
});

//- При изменении ширины окна браузера на ширине 1200px сворачивается меню, если оно было развернуто.
//- срабатывает событие клика на кнопку, чтобы и меню, и кнопка приняли необходимый вид для широких экранов
$(window).resize(function(){
	if($(window).width()>=1200){
		if($('.menu').hasClass('menu--visible')){
			$('.menu-button').click();
		}
	}
});



/*--------------PARALLAX -----------------*/

(function ($, window) {
  $.scrollParallax = function (el, options) {
    var _this = this;
    _this.$el = $(el);

    // Get option values ready
    options.tweenAmount = confineRange(options.tweenAmount, 1, 100);
    options.randomDepth.min = confineRange(options.randomDepth.min, 1, options.randomDepth.max);
    options.randomDepth.max = confineRange(options.randomDepth.max, options.randomDepth.min, 100);
    options.randomTween.min = confineRange(options.randomTween.min, 1, options.randomTween.max);
    options.randomTween.max = confineRange(options.randomTween.max, options.randomTween.min, 100);

    options.defaultDepth = confineRange(options.defaultDepth, 1, 100);

    if (options.random) {
      options.defaultDepth = Math.floor(Math.random() * (options.randomDepth.max - options.randomDepth.min) + options.randomDepth.min);
      options.tweenAmount = Math.floor(Math.random() * (options.randomTween.max - options.randomTween.min) + options.randomTween.min);
    }

    _this.loop = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame || window.oRequestAnimationFrame || function (callback) { window.setTimeout(callback, 1000 / 60); };
    _this.looping = false;

    // Preloader for <img> elements
    if (_this.$el.is('img')) {
      var $tempImg = $('<img/>');

      $tempImg
        .on('load', () => {
          init();

          $tempImg = null;
        })
        .attr('src', $(el).attr('src'));
    } else {
      init();
    }

    function init() {
      // Set initial offset
      _this.currentOffset = 0;

      // Get initial transform matrix
      _this.matrix = parseMatrix(_this.$el.css('transform'));

      // Set parallax depth
      _this.depth = typeof _this.$el.attr(options.parallaxAttr) !== typeof undefined && _this.$el.attr(options.parallaxAttr) !== false ? parseInt(_this.$el.attr(options.parallaxAttr), 10) : options.defaultDepth;
      _this.depth = (100 - _this.depth) / 100;

      // Set reference element
      _this.$ref = _this.$el.closest('[' + options.referenceAttr + ']').length ? _this.$el.closest('[' + options.referenceAttr + ']') : _this.$el.parent();

      // Set tweenAmount
      _this.tweenAmount = options.tweenAmount / 100;

      // Remove attributes
      _this.$el.removeAttr(options.parallaxAttr);
      _this.$ref.removeAttr(options.referenceAttr);

      // Watch for scroll
      scrollWatch();
    }

    function startParallax() {
      _this.looping = true;
      draw();
    }

    function draw() {
      updatePositions();

      if (_this.looping) {
        _this.loop.call(window, function () {
          return draw();
        });
      } else {
        scrollWatch();
      }
    }

    function updatePositions() {
      var scrollTop = $(window).scrollTop();
      var matrix = _this.matrix.slice(0);
      var newOffset = (_this.$ref.offset().top - scrollTop - $(window).height() / options.neutralPoint) * (1 - _this.depth);

      if (options.tween) {
        var delta = newOffset - _this.currentOffset;
        newOffset = _this.currentOffset + (delta * _this.tweenAmount);

        if (Math.abs(delta) < options.threshold) {
          _this.looping = false;
        }
      }

      matrix[5] += newOffset;

      _this.$el.css('transform', buildMatrix(matrix));
      _this.currentOffset = newOffset;
    }

    function setEvents() {
      $(window).on('resize.parallax', function () {
        if (!_this.looping) {
          startParallax();
        }
      });
    }

    function scrollWatch() {
      $(window).one('scroll.parallax', function () {
        if (!_this.looping) {
          startParallax();
        }
      });
    }

    function buildMatrix(matrix) {
      return 'matrix(' + matrix.join(',') + ')';
    }

    function parseMatrix(str) {
      var matrix = [1, 0, 0, 1, 0, 0];
      var matrixPattern = /^\w*\(([-+]?[0-9]*\.?[0-9]+(?:[eE][-+]?[0-9]+)?), ([-+]?[0-9]*\.?[0-9]+(?:[eE][-+]?[0-9]+)?), ([-+]?[0-9]*\.?[0-9]+(?:[eE][-+]?[0-9]+)?), ([-+]?[0-9]*\.?[0-9]+(?:[eE][-+]?[0-9]+)?), ([-+]?[0-9]*\.?[0-9]+(?:[eE][-+]?[0-9]+)?), ([-+]?[0-9]*\.?[0-9]+(?:[eE][-+]?[0-9]+)?)\)/i;

      if (matrixPattern.test(str)) {
        matrix = str.match(matrixPattern);
        matrix.shift();

        for (var i = 0; i < matrix.length; i++) {
          matrix[i] = Number(matrix[i]);
        }
      }

      return matrix;
    }

    function confineRange(value, min, max) {
      return value > max ? max : value < min ? min : value;
    }
  };

  $.fn.scrollParallax = function (options) {
    var options = $.extend({
      defaultDepth: 20,
      neutralPoint: 50,
      random: false,
      randomDepth: {
        min: 50,
        max: 80
      },
      randomTween: {
        min: 8,
        max: 12
      },
      referenceAttr: 'data-ref',
      parallaxAttr: 'data-parallax',
      threshold: 0.1,
      tween: true,
      tweenAmount: 10
    }, options);

    return this.each(function () {
      var scrollParallax = new $.scrollParallax(this, options);
    });
  };

} (jQuery, window));



$('.animated-style1').scrollParallax({
      defaultDepth: 20,
      neutralPoint: 10,
      random: false,
});

$('.animated-style2').scrollParallax({
      defaultDepth: 20,
      neutralPoint: 20,
      random: false,
});

$('.animated-style3').scrollParallax({
      defaultDepth: 5,
      neutralPoint: 10,
      random: false,
});

/*--------------END PARALLAX -----------------*/


/*--------------ФОРМЫ -----------------*/

//- Проверка полей форм на валидность
$("#form-contacts").submit(function (event) {
    event.preventDefault();
    if ($("#form-contacts")[0].checkValidity() === false) {
        event.stopPropagation();
        $("#form-contacts").addClass('was-validated');
        var t = "<div class='result_error'>Пожалуйста, проверьте правильность заполнения полей</div>";
        $("#form-contacts .form-result").empty();
        $("#form-contacts .form-result").append(t);
    } else {
      doajax("#form-contacts");
      $("#form-contacts").removeClass("was-validated");
      // $("#form-contacts")[0].reset();
        //do your ajax submition here
    }
});


$("#orderFormRu").submit(function (event) {
    event.preventDefault();
    if ($("#orderFormRu")[0].checkValidity() === false) {
        event.stopPropagation();
        $("#orderFormRu").addClass('was-validated');
        var t = "<div class='result_error'>Пожалуйста, проверьте правильность заполнения полей</div>";
    $("#orderFormRu .form-result").empty();
        $("#orderFormRu .form-result").append(t);
    } else {
      doajax("#orderFormRu");
      $("#orderFormRu").removeClass("was-validated");
      $("#orderFormRu")[0].reset();
        //do your ajax submition here
    }
});

$("#bookingexc").submit(function (event) {
    event.preventDefault();
    if ($("#bookingexc")[0].checkValidity() === false) {
        event.stopPropagation();
        $("#bookingexc").addClass('was-validated');
        var t = "<div class='result_error'>Пожалуйста, проверьте правильность заполнения полей</div>";
    $("#bookingexc .form-result").empty();
        $("#bookingexc .form-result").append(t);
    } else {
      doajax("#bookingexc");
      $("#bookingexc").removeClass("was-validated");
      $("#bookingexc")[0].reset();
      $("#bookingexc .bookingexc__hidden").css({
        'display': 'none',
        'visibility': 'hidden'
      });
        //do your ajax submition here
    }
});


function clearform(){
    $('#departure').val('');
    $('#direction').val('');
      $('.info-hotel').css("display", "none");
      $('.oneway-round').css("display", "none");
}


$(document).ready(clearform());



$(document).ready(function() {
 $('#departure').change(function() {
  if($(this).val() === "2")
   {
    $('.from-airport').css("display", "inline-block");
    $('.from-hotel').css("display", "none");
    $('.info-hotel').css("display", "block");
    $('.oneway-round').css("display", "block");
    $('.oneway').css("display", "none");   
    $('.round').css("display", "none");   
   }
   else if($(this).val() === "3")
   {
    $('.from-airport').css("display", "none");
    $('.from-hotel').css("display", "inline-block");
    $('.info-hotel').css("display", "block");
    $('.oneway-round').css("display", "block");
    $('.oneway').css("display", "none");   
    $('.round').css("display", "none");   
   }
   else {
      $('.info-hotel').css("display", "none");
      $('.oneway-round').css("display", "none");
  }
 });
});


$(document).ready(function() {
 $('#direction').change(function() {
  if($(this).val() === "2")
   {
    $('.oneway').css("display", "block");   
    $('.round').css("display", "none");
    $('#date-back').prop('required', false);  
    $('#flight-back').prop('required', false);

   }
   else if($(this).val() === "3")
   {
    $('.oneway').css("display", "block");   
    $('.round').css("display", "block");
    $('#date-back').prop('required', true);
    $('#flight-back').prop('required', true);
   }
   else{
      $('.info-hotel').css("display", "none");
      $('.oneway-round').css("display", "none");
  }
 });
}); 


//- Если у поля есть атрибут readonly, то не работает required.
//-  Хак: добавляем к полю атрибут readonly при получении фокуса, удаляем - при потере
//- В этом случае пользователь не может вводить свои значения в поле

$(function() {
 $('#date-there').focusin(function() {
    $('#date-there').attr("readonly", true);
 });
}); 

$(function() {
 $('#date-there').focusout(function() {
    $('#date-there').removeAttr("readonly");
 });
}); 

$(function() {
 $('#date-back').focusin(function() {
    $('#date-back').attr("readonly", true);
 });
}); 

$(function() {
 $('#date-back').focusout(function() {
    $('#date-back').removeAttr("readonly");
 });
}); 

$(function() {
 $('#date').focusin(function() {
    $('#date').attr("readonly", true);
 });
}); 

$(function() {
 $('#date').focusout(function() {
    $('#date').removeAttr("readonly");
 });
}); 


$(function() {
    $.datepicker.setDefaults($.datepicker.regional['it']);
    $("#date-there").datepicker({
     minDate: "+1",
     constrainInput: true,
     dateFormat: "d-MM-yy"
    });
    $("#date-back").datepicker({
     minDate: "+1",
     constrainInput: true,
     dateFormat: "d-MM-yy"
    });
    $("#date").datepicker({
     minDate: "+1",
     constrainInput: true,
     dateFormat: "d-MM-yy"
    });
    $('.ui-datepicker').addClass('notranslate');
}); 


//- Форма заказа экскурсий. Проверка имеется ли информация об экскурсии

// Передача информации об экскурсии в форму заказа
$(document).ready(function () {
  $(".sendexcinfo").on("click", function () {
    var vartitle=document.getElementById('datatosendtitle').innerHTML;
    var varguide=$(this).parent().parent().find('.prices__header').text();
    var varpersons=$(this).parent().find('.prices__npersons span').text();
    var varprice=$(this).parent().find('.prices__price').text();

    var objExc = {
      formtitle: vartitle,
      formguide: varguide,
      formpersons: varpersons,
      formprice: varprice
    };

    var serialObj = JSON.stringify(objExc); //сериализуем его
    localStorage.setItem("myKey", serialObj); //запишем его в хранилище по ключу "myKey"
  });
});

$(document).ready(function () {
  if (window.location.pathname == "/booking-excursion.html") {
    var returnObj = JSON.parse(localStorage.getItem("myKey"));
    $("#datatitle").val(returnObj['formtitle']);
    $(".datatitle--visible").text($("#datatitle").val());
    $("#dataguide").val(returnObj['formguide']);
    $("#datapersons").val(returnObj['formpersons']);
    $("#dataprice").val(returnObj['formprice']);
    localStorage.removeItem("myKey");

    if (($("#datatitle").val().length > 0) && ($("#dataguide").val().length > 0) && ($("#datapersons").val().length > 0) && ($("#dataprice").val().length > 0) )
      $(".form-booking-excursions").css("display", "block"),
      $(".bookingexc__empty").css("display", "none");
  }
});



//- Отправка данных форм
function doajax(activeform) {
  var t, a = $(activeform).serialize();
  var reason = $(activeform+" button").text();
  if (activeform=="#orderFormRu")
    var o = "php/form-order-process.php";
  else if (activeform=="#bookingexc")
    var o = "php/booking-excursions.php";
  else if (activeform=="#form-contacts")
    var o = "php/contactform.php";
  $.ajax({
    type: "POST",
    url: o,
    data: a,
    success: function (msg) {
      msg=$.trim(msg); //- удаляем лишние пробелы, символы, иначе из php файла получим строку success, которая не соответствует строке success в этом файле
      // Удаляем все из контейнера, в который выводятся результаты (на случай повторной отправки)
      $(activeform + " .form-result").empty();
      if (msg=="success")
        if (activeform=="#form-contacts")
          t = "<div class='result_success'>Ваше сообщение отправлено. Мы свяжемся с вами в ближайшее время!</div>",
          $(activeform + " .form-result").append(t),
          $(activeform)[0].reset()
        else
          t = "<div class='result_success'>Заявка на бронирование отправлена. Мы свяжемся с вами для подтверждения.</div>",
          $(activeform + " .form-result").append(t),
          $(activeform)[0].reset()
      else
        t = "<div class='result_error'>"+ msg +"</div>",
        $(activeform + " .form-result").empty(),
        $(activeform + " .form-result").append(t)
    },
    beforeSend: function () {
      $(activeform).find(":input").attr("disabled", !0),
      $(activeform + " .ajax-loader").css("display", "block")
    },
    complete: function () {
      $(activeform).find(":input").attr("disabled", !1),
      $(activeform + " .ajax-loader").css("display", "none"),
      $(activeform + " .form-result").css("display", "block");
      if (activeform=="#form-contacts"){
        grecaptcha.reset()
      }
      // $(activeform)[0].reset()
    }
  });
}


